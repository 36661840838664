import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import DownloadImage from "./DownloadImage";

class Package extends React.Component {
    on_show_package = (event) => {
        this.props.onShow(event)
    }
    on_start_again = (event) => {
        this.props.startOver(event)
    }
    package_contents() {
        return (
            <div>
                {this.show_wheel()}
                {this.show_accessories()}
                {this.show_fitting_info()}
            </div>
        )
    }
    package_bullets() {
        const bullets = ['Wheels & tyres will be fully assembled, valved and balanced', '4 days delivery'];
        return bullets.map(bullet => {
            return (
                <p className="m-0 text-uppercase">
                    <FontAwesomeIcon icon={faCheck} className="text-primary" /> {bullet}
                </p>
            )
        });
    }
    package_total() {
        let total_exc = Number(this.props.wheelData.price_exc);
        let total = Number(this.props.wheelData.price);
        this.props.accessories.map(accessory => {
            total_exc+= Number(accessory.price_exc);
            total+= Number(accessory.price);
        });
        total_exc = parseFloat(total_exc).toFixed(2);
        total = parseFloat(total).toFixed(2);
        return (
            <div className="border border-primary p-2 text-right">
                <p className="m-0 h5 text-primary text-uppercase">Total cost</p>
                <p className="m-0 h3 text-primary"><span className="font-weight-bolder">£ {total_exc}</span> excl. VAT</p>
                <p className="m-0 h3 text-primary"><span className="font-weight-bolder">£ {total}</span> inc. VAT</p>
            </div>
        )
    }
    show_wheel() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        return (
            <div>
                <div className="row no-gutters">
                    <h6>Selected wheel...</h6>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <DownloadImage imageLarge={this.props.wheelData.image_lg} imageSmall={this.props.wheelData.image}/>
                    </div>
                    <div className="col-12 col-md-8 col-lg-9">

                        <div className="row">
                            <div className="col-12 col-lg-6">
                                {renderHTML('h5', ['card-title'], this.props.wheelData.name)}
                                <p className="text-uppercase m-0">SKU: {this.props.wheelData.details.sku}</p>
                                {this.ean(this.props.wheelData)}
                                <p className="mt-2">
                                    <FontAwesomeIcon icon={faCheck} className="text-primary" /> Note: if a centre bore is greater than that of the hub, this is perfectly acceptable when using nuts to secure wheels. This is called 'nut centric'.
                                </p>
                            </div>
                            <div className="col-12 col-lg-6">
                                {this.get_prices(this.props.wheelData)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    ean(item) {
        if(item.details.ean){
            return (
                <p className="text-uppercase m-0">EAN: {item.details.ean}</p>
            )
        }
    }
    get_prices(item) {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        return (
            <div>
                {renderHTML('p', ['h5 m-0 text-uppercase'], 'Price EXCL. VAT: ' + item.currency + ' ' + item.price_exc)}
                {renderHTML('p', ['h5 font-weight-bolder text-uppercase'], 'Price INC. VAT: ' + item.currency + ' ' + item.price)}
            </div>
        )
    }
    show_accessories() {
        if(this.props.accessories.length){
            return (
                <div>
                    <div className="row no-gutters">
                        <div className="col-12">
                            <h6>Selected accessories...</h6>
                        </div>
                    </div>
                    {this.list_accessories()}
                </div>
            )
        }
    }
    list_accessories() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        console.log('package accessories: ' + this.props.accessories);
        return this.props.accessories.map((accessory_type, index) => {
            return (
                <div className="row" key={index}>
                    <div className="col-12 col-md-4 col-lg-3">
                        <DownloadImage imageLarge={accessory_type.image_lg} imageSmall={accessory_type.image}/>
                    </div>
                    <div className="col-12 col-md-8 col-lg-9">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                {renderHTML('h5', ['card-title'], accessory_type.title)}
                                <p className="text-uppercase m-0">SKU: {accessory_type.details.sku}</p>
                                {this.ean(accessory_type)}
                            </div>
                            <div className="col-12 col-lg-6">
                                {this.get_prices(accessory_type)}
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    }
    show_fitting_info() {
        if(this.props.tyreSizeInfo.comment){
            return (
                <div>
                    <div className="row no-gutters">
                        <div className="col-12">
                            <h6>Fitting information...</h6>
                            {this.vehicle_info()}
                            {this.fitted_with_info()}
                            <div className="alert alert-warning m-0" role="alert">
                                {this.props.tyreSizeInfo.comment}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="row no-gutters">
                    <div className="col-12">
                        <h6>Fitting information</h6>
                        {this.vehicle_info()}
                        {this.fitted_with_info()}
                        <div className="alert alert-success m-0" role="alert">
                            Tyre will fit vehicle without modification
                        </div>
                    </div>
                </div>
            )
        }

    }
    show_package() {
        if(this.props.tyreSize){ //Only allow package to be displayed if there's a tyre size
            if(this.props.showPackage){
                //Display package
                return (
                    <div>
                        <h5>Package summary</h5>
                        <div className="card mb-5">
                            <div className="card-body">
                                {this.package_contents()}
                            </div>
                        </div>
                        <div className="mb-5">
                            <button onClick={this.on_start_again} className="btn btn-lg btn-primary w-100">Finish and start again</button>
                        </div>
                    </div>
                )
            }else{
                //Button to show package
                return (
                    <div className="mb-5">
                        <button onClick={this.on_show_package} className="btn btn-lg btn-primary w-100">Generate package</button>
                    </div>
                )
            }
        }
    }
    vehicle_info() {
        return (
            <p>Vehicle: <span className="font-weight-bolder">{this.props.manufacturer} - {this.props.chassis_name}</span></p>
        )
    }
    fitted_with_info() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        return (
            <p>Fitted with: {renderHTML('span', 'font-weight-bolder', this.props.wheelData.name)} and <span className="font-weight-bolder">{this.props.tyreSizeInfo.section}/{this.props.tyreSizeInfo.profile}R{this.props.tyreSizeInfo.rim_size}</span> tyre</p>
        )
    }


    render() {
        return (
            <div id={this.props.id} className="mb-5">
                {this.show_package()}
            </div>
        )
    }
}

export default Package;
