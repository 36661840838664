import React from "react";
import Manufacturers from "./Manufacturers";
import Chassis from "./Chassis";
import WheelList from "./WheelList";
import TyreSizes from "./TyreSizes";
import Accessories from "./Accessories";
import Package from "./Package";

class App extends React.Component {
    state = { manufacturer_id: null, manufacturer_name: null, chassis_id: null, chassis_name: null, chassis_info: null, wheel_id: null, wheel_id_post_id: null, wheel_data: null, tyre_size: null, tyre_size_info: null, accessories: [], show_package: null };
    onManufacturerSelect = (manufacturer_id, manufacturer) =>  {
        console.log('manufacturer with id: ' + manufacturer_id + ' was selected');
        this.setState({manufacturer_id: manufacturer_id, manufacturer_name: manufacturer});
    }

    onChassisSelect = (chassis_id, chassis, chassis_info) => {
        this.setState({chassis_id: chassis_id, chassis_name: chassis, chassis_info: chassis_info[0], wheel_id: null, wheel_id_post_id: null, wheel_data: null, tyre_size: null, tyre_size_info: null, show_package: null});
    }

    onWheelSelect = (data) => {
        console.log('wheel with id ' + data.id + ' was selected');
        console.log('wheel with post id ' + data.post_id + ' was selected');
        this.setState( {wheel_id: data.id, wheel_id_post_id: data.post_id, wheel_data: data, tyre_size: null, tyre_size_info: null, accessories: [], show_package: null});
        const scrollTarget = document.getElementById('pbc-tyre-sizes');
        scrollTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    onTyreSizeSelect = (tyre_size, size_info) => {
        console.log(tyre_size + ' tyre size selected');
        console.log(size_info);
        this.setState({ tyre_size: tyre_size, tyre_size_info: size_info, show_package: null });
        const scrollTarget = document.getElementById('pbc-accessories');
        scrollTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    onAccessorySelect = (data) => {
        if(!this.state.accessories.find(o => o.id === data.id)){
            this.setState({accessories: [...this.state.accessories, data]});
        }
    }

    onAccessoryRemove = (data) => {
        console.log('removing item ' + data.id);
        const filtered = this.state.accessories.filter(o => o.id !== data.id);
        this.setState({ accessories: filtered });
    }

    onPackageShow = (event) => {
        console.log('package show ' + event);
        this.setState({show_package: true});
        const scrollTarget = document.getElementById('pbc-package');
        scrollTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    onStartOver = (event) => {
        console.log('start over here');
        const scrollTarget = document.getElementById('pbc-top');
        scrollTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
        setTimeout(()=>{
            this.setState({ manufacturer_id: null, chassis_id: null, wheel_id: null, wheel_data: null, tyre_size: null, accessories: [], show_package: null });
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.state.accessories);
    }

    render() {
        return (
            <div className="container" id="pbc-top">
                <div className="row">
                    <div className="col mt-4">
                        <h1 className="display-4 font-weight-lighter mb-4 d-md-flex pb-title">
                            <img src="images/MDT-logo.png" alt="Micheldever Tyre Services Ltd" className="d-block mb-4 mb-md-0 mt-2 mr-5 pb-title__brand-logo"/>
                            <span className="pb-title__text">Wheel & Accessory<br/>Package Builder</span>
                        </h1>
                        <p>Use this tool to quickly pull together a bundle package of wheels and accessories for the vehicle of your choice – complete with vehicle information and fitment instructions.</p>
                        <p className="font-weight-bolder">
                            How to use:
                        </p>
                        <ol>
                            <li><span className="font-weight-bolder">Select make and model of vehicle</span> – the vehicle info will display directly below your selection to help you choose the right wheel for your customer.</li>
                            <li><span className="font-weight-bolder">Select the wheel you want</span> – you can filter available sizes using the check boxes provided.</li>
                            <li><span className="font-weight-bolder">Choose the relevant tyre size</span> to display any special fitting instructions.</li>
                            <li><span className="font-weight-bolder">Choose all the accessories</span> you want to add to the package.</li>
                            <li><span className="font-weight-bolder">Hit the 'Generate Package' button</span> to create a summary of your wheel and accessories with combined price and fitting instructions!</li>
                            <li><span className="font-weight-bolder">Hit ‘Finish and Start Again’ to reset the package builder</span> and build another bundle. You can also just refresh your page if you get stuck.</li>
                        </ol>
                    </div>
                </div>
                <div className="form-row mt-4">
                    <div className="col">
                        <Manufacturers id="pbc-manufacturers" onChange={ this.onManufacturerSelect }/>
                    </div>
                    <div className="col">
                        <Chassis id="pbc-chassis" manufacturer_id={ this.state.manufacturer_id } onChange={ this.onChassisSelect }/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <WheelList id="pbc-wheels" chassis_id={ this.state.chassis_id } chassis_info={this.state.chassis_info} onSelect={ this.onWheelSelect }/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TyreSizes
                            id="pbc-tyre-sizes"
                            selectedWheel={this.state.wheel_id}
                            wheelData={this.state.wheel_data}
                            chassis={this.state.chassis_id}
                            manufacturer={this.state.manufacturer_name}
                            chassis_name={this.state.chassis_name}
                            onSelect={this.onTyreSizeSelect}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Accessories
                            id="pbc-accessories"
                            tyreSizeIndex={this.state.tyre_size}
                            chassis={this.state.chassis_id}
                            wheelID={this.state.wheel_id}
                            wheelPostID={this.state.wheel_id_post_id}
                            onSelect={this.onAccessorySelect}
                            onRemove={this.onAccessoryRemove}
                            selectedAccessories={this.state.accessories}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Package
                            id="pbc-package"
                            wheelData={this.state.wheel_data}
                            accessories={this.state.accessories}
                            tyreSize={this.state.tyre_size}
                            tyreSizeInfo={this.state.tyre_size_info}
                            showPackage={this.state.show_package}
                            manufacturer={this.state.manufacturer_name}
                            chassis_name={this.state.chassis_name}
                            onShow={this.onPackageShow}
                            startOver={this.onStartOver}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default App;
