import React from "react";
import axios from "axios";
import Wheel from "./Wheel";

class WheelList extends React.Component {
    state = { chassis: null, wheels: null, filters: [], selectedWheel: null, vehicle_info: null };
    loading = false;

    componentDidUpdate() {
        if(this.props.chassis_id !== this.state.chassis){
            this.setState({ chassis: this.props.chassis_id, wheels: [], filters: [], selectedWheel: null, selectedWheelPostId: null, vehicle_info: null
            });
            console.log('Updated state chassis to ' + this.props.chassis_id);
            this.getWheels(this.props.chassis_id);
            this.getChassisDetail(this.props.chassis_id);
        }
    }

    async getWheels(chassis_id) {
        this.loading = true;
        const response = await axios.get('https://4x4tyres.co.uk/api/v2/pb_get_wheels', {
            params: {
                id: chassis_id
            }
        });
        this.loading = false;
        this.setState({wheels: response.data.results});
    }

    async getChassisDetail(chassis_id) {
        this.loading = true;

        const chassis_response = await axios.get('https://4x4tyres.co.uk/api/v2/pb_get_chassis_detail', {
            params: {
                id: chassis_id
            }
        });

        this.setState({vehicle_info: chassis_response.data.results});
    }

    onWheelSelect = (data) => {
        this.props.onSelect(data);
        this.setState({ selectedWheel: data.id, selectedWheelPostId: data.post_id });
    }

    onFilter = (event) => {
        let filters = this.state.filters;
        if(event.target.checked){
            //Add the item
            filters.push(event.target.value);
        }else{
            //Remove the item
            filters = filters.filter(f => f !== event.target.value);
        }
        console.log(filters);
        this.setState({filters: filters});
    }

    wheels() {
        return this.state.wheels.map((wheel) => {
            return (
                this.filterWheel(wheel)
            )
        });
    }


    filterWheel(wheel) {
        if(this.state.filters.length){
            if(this.state.filters.indexOf(wheel.details.wheel_size)!==-1){
                return (
                    <Wheel
                        key={wheel.id}
                        wheelData={wheel}
                        wheelID={wheel.id}
                        onSelect={this.onWheelSelect}
                        selectedWheel={this.state.selectedWheel}
                    />
                )
            }
        }else{
            return (
                <Wheel
                    key={wheel.id}
                    wheelData={wheel}
                    wheelID={wheel.id}
                    onSelect={this.onWheelSelect}
                    selectedWheel={this.state.selectedWheel}
                />
            )
        }
    }

    wheelsSizeFilter() {
        const unique_sizes = [...new Set(this.state.wheels.map(item => item.details.wheel_size))];
        unique_sizes.sort();
        return (
            <div className="pb-wheel-size-filter mb-3">
                <h5>Filter wheels by size:</h5>
                <div>
                    {this.checkboxes(unique_sizes, 'size-filter')}
                </div>
            </div>
        )
    }

    checkboxes(sizes, ele) {
        return sizes.map((size, index) => {
            const sizeText = size.replace(/&quot;/gi, `"`);
            return (
                <div key={index} className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name={ele} value={size} id={ele + '-' + index} onChange={this.onFilter}/>
                    <label className="form-check-label" htmlFor={ele + '-' + index}>{sizeText}</label>
                </div>
            )
        });
    }

    chassis_info() {
        console.log(this.state.vehicle_info);
        return (
            <div>
                <h5>Vehicle info...</h5>
                <div className="pb-info p-2 mb-4 small">
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            PCD
                        </span>
                        <span>
                            {this.state.vehicle_info.chassis.pcd.holes}/{this.state.vehicle_info.chassis.pcd.bcd}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            Centre Bore
                        </span>
                        <span>
                            {this.state.vehicle_info.chassis.center_bore}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            Nut or Bolt
                        </span>
                        <span>
                            {this.state.vehicle_info.chassis.wheel_fasteners=='Lug nuts'?'Nuts':'Bolts'}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            Torque
                        </span>
                        <span>
                            {this.state.vehicle_info.chassis.torque_nm}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            Nutbolt Thread type
                        </span>
                        <span>
                            {this.state.vehicle_info.chassis.thread_size}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    renderWheels() {
        if(this.state.chassis){
            if(this.state.wheels.length){
                return (
                    <div>
                        {this.chassis_info()}
                        {this.wheelsSizeFilter()}
                        <div className="pb-wheel-list mb-5">
                            {this.wheels()}
                        </div>
                    </div>
                )
            }else{
                if(this.loading){
                    return (
                        <div className="d-flex align-items-center">
                            <div className="spinner-border spinner-border-sm text-secondary mr-2" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div>
                                Loading wheels...
                            </div>
                        </div>
                    )
                }else{
                    return (
                        <div>No Wheels Currently Offered.</div>
                    )
                }
            }
        }else{
            return (
                <div>Nothing to show yet</div>
            )
        }
    }

    render() {
        return (
            this.renderWheels()
        )
    }
}

export default WheelList;
