import React from "react";
import axios from "axios";

class TyreSizes extends React.Component {
    state = {wheel_id: null, wheel_id_post_id: null, wheel_sizes: [], selected: null};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.selectedWheel){
            if(this.props.selectedWheel !== this.state.wheel_id){
                this.setState({wheel_id: this.props.selectedWheel, wheel_sizes: []});
                console.log('now get tyre sizes for wheel:' + this.props.selectedWheel);
                this.getTyreSizes();
            }
        }
        if(this.state.selected){
            if(prevState.selected !== this.state.selected){
                this.props.onSelect(this.state.selected, this.state.wheel_sizes[this.state.selected]);
            }
        }
    }

    async getTyreSizes() {
        const response = await axios.get('https://4x4tyres.co.uk/api/v2/pb_get_tyre_sizes', {
            params: {
                chassis_id: this.props.chassis,
                wheel_id: this.props.selectedWheel
            }
        });
        const ws = [];
        for(const [key, value] of Object.entries(response.data.results)){
            ws.push(value);
        }
        this.setState({ wheel_sizes: ws, selected: null });
    }

    onClick = (event) => {
        this.setState({ selected: event.target.value });
    }

    get_button_state(index) {
        return index.toString() === this.state.selected ? 'btn-primary' : 'btn-secondary';
    }

    buttons () {
        return this.state.wheel_sizes.map((size, index) => {
            return (
                <div className="col-12 col-md-4 col-lg-3" key={index}>
                    <button className={`btn ${this.get_button_state(index)} w-100 mt-1 mb-1`} onClick={this.onClick} key={index} value={index}>{size.section}/{size.profile}R{size.rim_size}</button>
                </div>
            )
        });
    }

    fitting_info() {
        if(this.state.selected){
            const size = this.state.wheel_sizes[this.state.selected];
            if(size.comment){
                return (
                    <div className="mt-4">
                        <h5>Fitting information</h5>
                        {this.vehicle_info()}
                        {this.fitted_with_info()}
                        <div className="alert alert-warning m-0" role="alert">
                            {size.comment}
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="mt-4">
                        <h5>Fitting information</h5>
                        {this.vehicle_info()}
                        {this.fitted_with_info()}
                        <div className="alert alert-success m-0" role="alert">
                            Tyre will fit vehicle without modification
                        </div>
                    </div>
                )
            }
        }
    }

    vehicle_info() {
        return (
            <p>Vehicle: <span className="font-weight-bolder">{this.props.manufacturer} - {this.props.chassis_name}</span></p>
        )
    }

    fitted_with_info() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        return (
            <p>Fitted with: {renderHTML('span', 'font-weight-bolder', this.props.wheelData.name)} and <span className="font-weight-bolder">{this.state.wheel_sizes[this.state.selected].size}</span> tyre</p>
        )
    }

    render_wheel_sizes() {
        return (
            <div className="card mb-3 shadow-sm">
                <div className="card-body">
                    <div className="form-row">
                        {this.buttons()}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {this.fitting_info()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    info() {
        if(this.props.selectedWheel) {
            console.log('wheel sizes');
            console.log(this.state.wheel_sizes);
            if(this.state.wheel_sizes.length){
                return (
                    <div>
                        <h5>Available tyre sizes for this wheel... select one to continue:</h5>
                        <div>
                            {this.render_wheel_sizes()}
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="d-flex align-items-center">
                        <div className="spinner-border spinner-border-sm text-secondary mr-2" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div>
                            Loading tyre sizes...
                        </div>
                    </div>
                )
            }
        }else{
            return <div>&nbsp;</div>
        }
    }
    render() {
        return (
            <div id={this.props.id} className="mb-5">
                {this.info()}
            </div>
        )
    }

}

export default TyreSizes;
