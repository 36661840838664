import React from "react";
import axios from "axios";
import ModalImage from "react-modal-image";

class DownloadImage extends React.Component {
    state = {href: null};
    componentDidMount() {
        this.imgBlob();
    }

    async imgBlob() {
        try {
            const response = await axios({
                url: this.props.imageLarge,
                method: 'GET',
                responseType: 'blob'
            });
            let reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = () => {
                let base64 = reader.result;
                this.setState({href: base64});
            }
        } catch(err) {
            console.error("Error response:");
            console.error(err.response.data);    // ***
            console.error(err.response.status);  // ***
            console.error(err.response.headers); // ***
        }
    }

    render() {
        if(this.state.href){
            return <ModalImage
                className="card-img p-2 img-thumbnail mb-3"
                large={this.state.href}
                small={this.props.imageSmall}
                alt=""
            />
        }else{
            return (
                <img src={this.props.imageSmall} className="card-img p-2 img-thumbnail mb-3" alt=""/>
            )
        }
    }
}

export default DownloadImage;
