import React from "react";
import axios from "axios";


class Manufacturers extends React.Component {
    state = { manufacturers: [] };

    constructor(props) {
        super(props);
        this.getManufacturers();
    }

    async getManufacturers() {
        const response = await axios.get('https://4x4tyres.co.uk/api/v2/pb_get_manufacturers');
        this.setState({manufacturers: response.data.results});
    }

    onManufacturerSelect = (event) => {
        event.preventDefault();
        let index = event.nativeEvent.target.selectedIndex;
        this.props.onChange(event.target.value, event.nativeEvent.target[index].text);
    }

    getOptions() {
        if(this.state.manufacturers.length){
            const man_opts = [
                {
                    id: null,
                    name: 'Select...'
                }, ...this.state.manufacturers
            ]

            return man_opts.map((manufacturer) => {
                return (
                    <option value={ manufacturer.id } key={ manufacturer.id }>{ manufacturer.name }</option>
                );
            });
        }else{
            return (
                <option value="" key="0">Loading...</option>
            )
        }
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor="select-manufacturer">Manufacturer</label>
                <select id="select-manufacturer" className="form-control" onChange={ this.onManufacturerSelect }>{ this.getOptions() }</select>
            </div>

        );
    }
}

export default Manufacturers;
