import React from "react";
import axios from "axios";

class Chassis extends React.Component {

    state = { manu: null, chassis: [] }

    componentDidUpdate() {
        if(this.props.manufacturer_id !== this.state.manu){
            this.setState({ manu: this.props.manufacturer_id, chassis: [] });
            this.getChassis(this.props.manufacturer_id);
        }
    }

    async getChassis(manufacturer_id) {
        const response = await axios.get('https://4x4tyres.co.uk/api/v2/pb_get_chassis', {
            params: {
                id: manufacturer_id
            }
        });
        this.setState({chassis: response.data.results });
    }

    getOptions() {
        // eslint-disable-next-line
        const year_start = null, year_end = null, start = null, end = null;

        if(this.state.manu){
            //Loop through options
            if(this.state.chassis.length){
                const chassis_opts = [
                    {
                        id: null,
                        name: 'Select chassis...'
                    }, ...this.state.chassis
                ]
                return chassis_opts.map((chassis) => {
                    console.log(chassis.chassis);
                    if(chassis.year_end){
                        this.end = new Date(chassis.year_end);
                        this.end_year = this.end.getFullYear();
                    }else{
                        this.end_year = '';
                    }
                    if(chassis.year_start){
                        this.start = new Date(chassis.year_start);
                        this.start_year = this.start.getFullYear();
                    }else{
                        this.start_year = '';
                    }
                    return (
                        <option value={typeof chassis.chassis === 'undefined' ? '' : chassis.chassis.id} key={typeof chassis.chassis === 'undefined' ? '' : chassis.chassis.id}>{typeof chassis.chassis === 'undefined' ? chassis.name : chassis.chassis.display_name}</option>
                    )
                });
            }else{
                return (
                    <option value="" key="0">Loading...</option>
                )
            }
        }else{
            return (
                <option value="" key="0">Select a manufacturer first</option>
            )
        }
    }

    onChassisSelect = (event) => {
        event.preventDefault();
        let index = event.nativeEvent.target.selectedIndex;
        const selected_chassis = this.state.chassis.filter(f => f.chassis.id.toString() === event.target.value);
        this.props.onChange(event.target.value, event.nativeEvent.target[index].text, selected_chassis);
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor="select-chassis">Chassis</label>
                <select id="select-chassis" className="form-control" onChange={this.onChassisSelect}>
                    { this.getOptions() }
                </select>
            </div>
        )
    }
}

export default Chassis;
