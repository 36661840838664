import React from "react";
import DownloadImage from "./DownloadImage";

class Accessory extends React.Component {
    onSelect = (event) => {
        this.props.onSelect(this.props.itemData);
    }

    onRemove = (event) => {
        this.props.onRemove(this.props.itemData);
    }

    get_brand() {
        if(this.props.itemData.brand.logo){
            return (
                <div className="pb-brand">
                    <img src={this.props.itemData.brand.logo} alt={this.props.itemData.brand.name} className="w-100 img-fluid"/>
                </div>
            )
        }else{
            return (
                <div className="pb-brand text">
                    <p className="text-center font-weight-bolder">{this.props.itemData.brand.name}</p>
                </div>
            )
        }
    }

    get_stock() {
        return (
            <div>
                <p className="h6 text-center text-primary mt-4 mb-0">{this.props.itemData.stock} in stock</p>
            </div>
        )
    }

    get_info() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        return (
            <div className="pb-info p-2 mb-2 small">
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Brand name
                    </p>
                    {renderHTML('p', ['m-0'], this.props.itemData.brand.name)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Product type
                    </p>
                    {renderHTML('p', ['m-0'], this.props.itemData.details.product_type)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Weight
                    </p>
                    {renderHTML('p', ['m-0'], this.props.itemData.details.weight)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        SKU
                    </p>
                    {renderHTML('p', ['m-0'], this.props.itemData.details.sku)}
                </div>
                {this.ean()}
            </div>
        )
    }

    ean() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        if(this.props.itemData.details.ean){
            return (
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        EAN
                    </p>
                    {renderHTML('p', ['m-0'], this.props.itemData.details.ean)}
                </div>
            )
        }
    }

    get_prices() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        return (
            <div>
                {renderHTML('p', ['h5 m-0 text-uppercase'], 'Price EXCL. VAT: ' + this.props.itemData.currency + ' ' + this.props.itemData.price_exc)}
                {renderHTML('p', ['h5 font-weight-bolder text-uppercase'], 'Price INC. VAT: ' + this.props.itemData.currency + ' ' + this.props.itemData.price)}
            </div>
        )
    }

    render() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        const btn_class = this.props.selected ? 'btn-primary' : 'btn-secondary';
        const btn_label = this.props.selected ? 'Add to package' : 'Remove from package';
        const btn_action = this.props.selected ? this.onSelect : this.onRemove;
        console.log(this.props.selected);
        return (
            <div className="mb-4 pb-4 border-bottom">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <DownloadImage imageLarge={this.props.itemData.image_lg} imageSmall={this.props.itemData.image}/>
                        {this.get_brand()}
                        {this.get_stock()}
                    </div>
                    <div className="col-12 col-md-8 col-lg-9">
                        <div className="row">
                            <div className="col-12">
                                {renderHTML('h5', ['card-title'], this.props.itemData.title)}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                {this.get_info()}
                            </div>
                            <div className="col-12 col-lg-6">
                                {this.get_prices()}
                                <button className={`btn ${btn_class}`} onClick={btn_action} value={this.props.itemData.id}>{`${btn_label}`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Accessory
